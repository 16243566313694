import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-5" }
const _hoisted_2 = { class: "page_padding" }
const _hoisted_3 = { class: "chatDetail" }
const _hoisted_4 = { class: "p-2 pt-3 chatScroller" }
const _hoisted_5 = {
  class: "chatMessages",
  id: "chatMessagesContainer",
  ref: "chatMessagesContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_cloud = _resolveComponent("cloud")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_localized_text, {
        class: "fw_600",
        type: "h3",
        localizedKey: "backoffice_dettaglioChatNode",
        text: "Dettaglio chatnode"
      }),
      _createVNode("p", null, _toDisplayString(_ctx.chatRoom.user.text), 1)
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", _hoisted_4, [
        _createVNode("div", _hoisted_5, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.chatRoom.messages, (message, index) => {
            return (_openBlock(), _createBlock("div", { key: index }, [
              _createVNode(_component_cloud, {
                text: message.text,
                senderName: _ctx.getSender(message),
                position: _ctx.senderIsMe(message) ? 'right' : 'left',
                time: _ctx.$filters.date(message.createdOn)
              }, null, 8, ["text", "senderName", "position", "time"])
            ]))
          }), 128))
        ], 512)
      ])
    ])
  ]))
}
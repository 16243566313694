
import { Options, Vue } from 'vue-class-component';
import { ChatNodeChatClient } from '@/services/Services';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class chatNodeDetail extends Vue {

    chatRoom: OM.ChatRoomDetailVM = new OM.ChatRoomDetailVM();

    created() {
        ChatNodeChatClient.getChatRoomMessages(this.$route.params.identifier.toString())
        .then(x => {
            this.chatRoom = x;
        })
    }

    senderIsMe(message: OM.ChatNodeChatMessage) {
        if(message.senderIdentifier)
            return true;
        else
            return false;
    }

    getSender(message: OM.ChatNodeChatMessage){
        if(message.senderIdentifier)
            return this.chatRoom.user.text;
        else
            return "Chatnode bot";
    }

}
